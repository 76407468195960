import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: '523.snap.com',

  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.SNAP_523 },
      {
        vendor: LoggingVendor.GOOGLE,
        googleAnalyticsId: 'G-QSNJ69K7MK',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: '523-snap-com',
        dsn: 'https://2f1f3960e1724d89a107986a0e85d280@sentry.sc-prod.net/165',
      },
    ],
  },
  enabledCdn: true,
};
